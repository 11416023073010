
.VideoTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    max-height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-size-adjust: 100%;
    vertical-align: middle;
    cursor: pointer
}

